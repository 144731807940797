<template>
    <maintenance-page
        page-title="Item"
        page-icon="mdi-format-list-bulleted"
        collection-name="item"
        done-redirect-url="/auth/admin/item?isHistory=true"

    />
</template>
<!--        :dataSets="{ taxStatuses }"-->
<script>
import MaintenancePage from '../../../components/pages/Maintenance';

export default {
    name: 'ItemDetail',
    components: { MaintenancePage },

    methods: {
        async init() {},
    },
    async mounted() {
        await this.init();
    },
};
</script>
